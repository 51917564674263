


import React from 'react'
import {PostWrap} from "../basics"
import Link from "gatsby-link"
import { StaticQuery, graphql } from 'gatsby'

import Img from "gatsby-image"

import './glazial.css'


  
var remember_last_category = [];



const Glazial = () => (
<StaticQuery
    query={graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___category, frontmatter___date], order: DESC }) {
        totalCount
        edges {
          node {
            frontmatter {
              title
              date(formatString: "DD MMMM, YYYY")
              category


              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 140) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
              } }




            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `} 
render={data => (

    
    <PostWrap>
    
      <div style={{textAlign:"center"}}>
    <p>In holonoptic™ you will control our company, the GLAZIAL conglomerate.</p> 
    <h5>{data.allMarkdownRemark.totalCount} documents recovered from company archives</h5>

      </div>
    

    <div className="allPosts">  {/* eslint-disable-next-line */}
    { remember_last_category = [],
      data.allMarkdownRemark.edges.map(({ node }, index) =>
    
    
    {                            
        if ( remember_last_category.includes(node.frontmatter.category) !== true )

                        {
                                    remember_last_category.push(node.frontmatter.category);
                                    
                                    return (    <>
                                                <h2 className="postCategoryTitle">
                                                {node.frontmatter.category} 
                                                </h2>  
                                                

                                                <div className="post">
                                                    <Link 
                                                        to={node.fields.slug}
                                                    >
                                                        <Img fluid={node.frontmatter.thumbnail.childImageSharp.fluid} alt="" className="postImage"  />
                                                            <div className="postTexts">
                                                                <h4 className="postTitle">{node.frontmatter.title} </h4>
                                                                <p className="postDate">{node.frontmatter.date}</p>
                                                                <p className="postExcerpt">{node.excerpt}</p>
                                                            </div>
                                                    </Link>
                                                    </div>
                                                </>

                                                
                                                );
                        
                        }

        else {return (
            <div key={index} className="post">
            <Link 
                to={node.fields.slug}
            >
                <Img fluid={node.frontmatter.thumbnail.childImageSharp.fluid} alt="" className="postImage" />
                    <div className="postTexts">
                        <h4 className="postTitle">{node.frontmatter.title}</h4>
                        <p className="postDate">{node.frontmatter.date}</p>
                        <p className="postExcerpt">{node.excerpt}</p>
                    </div>
            </Link>
            </div>
            );}
    }
    )}

</div>


  </PostWrap>


)}
/>
)


export default Glazial