import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import {Wrap} from "../basics"



import './glazial.css'


  


const AllPosts = (props) => (
<StaticQuery
    query={graphql`
    query {
      allMarkdownRemark(
            sort: { fields: [frontmatter___category, frontmatter___date], order: DESC,  }
            ) {
        totalCount
        
        edges {
          node {
            html
            frontmatter {
              title
              date(formatString: "DD MMMM, YYYY")
              category


              thumbnail {
                childImageSharp {
                    fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
              } }




            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `} 
render={data => (<>
        { data.allMarkdownRemark.edges.map(({ node }) => (
    
    
        <Wrap>
      <hr style={{marginTop: "4vw", marginBottom:"4vw", borderTop: "2px solid #77d2ff" }}></hr>

    <h1>
    {node.frontmatter.title} {props.size}
  </h1>

  <div className = "MarkdownPost">

                <div dangerouslySetInnerHTML={{ __html: node.html }} /> 

            </div></Wrap>

    ))  }



  </>


)}
/>
)


export default AllPosts