import React, { Component }  from 'react'

import Layout from '../../components/layout'
import AllPosts from '../../components/glazial/all_posts'
import Glazial from "../../components/glazial/glazial"
import {CenterContainer, SiteTitle} from "../../components/basics"

import ToggleDisplay from 'react-toggle-display';



// IMAGES

import glazial_logo from '../../images/main/glazial_white.png'


class World extends Component {
  constructor() {
    super();
    this.state = { show: false };
  }

  handleClick() {
    this.setState({
      show: !this.state.show
    });
  }
  render() {
    return (


<>
<SiteTitle string="holonoptic™ | world" />

  <Layout>




  <CenterContainer>
  <div style={{zIndex:-1}} >
  <img  src={glazial_logo} alt="" id="glazial_logo" />
  </div>
  </CenterContainer>


  <CenterContainer>
  <button onClick={ () => this.handleClick() }>toggle overview/scroll</button>
  </CenterContainer>


          <ToggleDisplay if={this.state.show === false} >        
          <Glazial />
          </ToggleDisplay>


          <ToggleDisplay if={this.state.show}  >
        <AllPosts />
        </ToggleDisplay>
  

  </Layout>
</>
    );
  }
}



export default World;